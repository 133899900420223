var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('vbt-search',{class:{ 'search-with-tags': !_vm.searchData.tags || !_vm.searchData.tags.length },attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading":_vm.loading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [(_vm.hadAdminRole)?_c('v-btn',{attrs:{"to":{
          name: 'newRule',
          query: {
            suffix: _vm.suffix,
          },
        },"x-small":"","color":"info"}},[_vm._v(" Create rule ")]):_vm._e()]},proxy:true}])},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.rules},scopedSlots:_vm._u([{key:"id",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{staticClass:"white--text",staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: _vm.hadAdminRole ? 'ruleEdit' : 'ruleDetails',
            query: {
              suffix: _vm.suffix,
            },
            params: {
              id: item.id,
            },
          }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"status",fn:function(ref){
          var item = ref.item;
return [_vm._l(((item.status || [])),function(status){return _c('dsb-entity-badge',{key:_vm.genGUID(status),staticClass:"mr-2",attrs:{"entity":status}})}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_c('dsb-entity-badge',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s((item.statistic || []).map(function (ref) {
                  var c = ref.pressSheetCount;

                  return c;
          }).join(' / '))+" ")])],1)]}}],null,true)},[_vm._v(" Press Sheets for the last: "+_vm._s((item.statistic || []).map(function (ref) {
                  var p = ref.period;

                  return p;
          }).join(' / '))+" ")])]}},{key:"coverLamUVFoil",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUVOrientationStrings(item.orientation || []).join('; '))+" / "+_vm._s((item.laminating || []).join('; ') || '-')+" / "+_vm._s((item.variableUv || []).join('; ') || '-')+" / "+_vm._s((item.cover || []).join('; ') || '-')+" / "+_vm._s((item.foiling || []).join('; ') || '-')+" / "+_vm._s((item.roundedCorners || []).join('; ') || '-')+" ")]}},{key:"quantity",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.minQuantity)+" - "+_vm._s(item.maxQuantity)+" ")]}},{key:"pressSheetTemplates",fn:function(ref){
          var item = ref.item;
return [_c('v-layout',{attrs:{"column":""}},_vm._l((item.pressSheetTemplates || []),function(template,i){return _c('span',{key:("p-tmpl-" + i),staticClass:"mb-2"},[_vm._v(" "+_vm._s(template)+" ")])}),0)]}},{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('v-layout',{attrs:{"justify-space-between":""}},[(_vm.hadAdminRole)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","small":"","icon":""},on:{"click":function($event){return _vm.showProFITSyncModal(item.id)}}},on),[_c('v-icon',[_vm._v(" sync ")])],1)]}}],null,true)},[_vm._v(" Sync order items with ProFIT by this rule ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.hadAdminRole ? 'warning' : 'info',"to":{
                  name: _vm.hadAdminRole ? 'ruleEdit' : 'ruleDetails',
                  query: {
                    suffix: _vm.suffix,
                  },
                  params: {
                    id: item.id,
                  },
                },"small":"","icon":""}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.hadAdminRole ? 'create' : 'remove_red_eye')+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(("" + (_vm.hadAdminRole ? 'Edit' : 'Show')))+" rule details ")]),(_vm.hadAdminRole)?_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.removeRule(item.id)}}},[_c('v-icon',[_vm._v(" delete ")])],1):_vm._e()],1)]}}])}),_c('vbt-pagination',{attrs:{"items-count":_vm.pagedItemsCount,"total-count":_vm.rulesTotalCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1),_c('dsb-rule-profit-sync',{attrs:{"rule-id":_vm.ruleId},model:{value:(_vm.proFITSyncDialog),callback:function ($$v) {_vm.proFITSyncDialog=$$v},expression:"proFITSyncDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }