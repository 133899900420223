<template>
  <vbt-dialog
    v-model="dialog"
    width="500px"
    submit-btn-name="Sync"
    title="Sync orders with ProFIT"
    @submit="syncOrders"
    @close="clearForm"
  >
    <strong>
      Expires period
    </strong>

    <vbt-date-picker
      v-model="dates"
      :clearable="false"
      :min="allowedDatesRange.min"
      :max="allowedDatesRange.max"
      range
    />
  </vbt-dialog>
</template>

<script>
import { mapState } from 'vuex';

import {
  getAllowedDatePickerRange,
  getDatesRangeByLastDays,
  parseDateStringToISO,
  wrapToLoadingFn,
} from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { RulesApiService } from '../_services/rules.api.service';

export default {
  name: 'DsbRuleProfitSync',

  model: {
    event: 'input',
    prop: 'value',
  },

  props: {
    ruleId: {
      type: [String, Number],
      required: true,
    },
    value: Boolean,
  },

  data() {
    return {
      dates: getDatesRangeByLastDays(),
      allowedDatesRange: getAllowedDatePickerRange(),
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    dialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    syncOrders() {
      this.$VBlackerTheme.alert.warning({
        text: 'Sync orders?',
      }, () => this.wrapToLoadingFn({
        req: RulesApiService.syncRuleWithProFIT.bind({}, {
          suffix: this.suffix,
          id: this.ruleId,
          data: {
            from: parseDateStringToISO(this.dates[0]),
            to: parseDateStringToISO(`${this.dates[1]} 23:59:59`),
          },
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully synced');

          this.dialog = false;
        },
      }));
    },

    clearForm() {
      this.dates = getDatesRangeByLastDays();
    },

    wrapToLoadingFn,
  },
};
</script>
