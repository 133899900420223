import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { genGUID, wrapToLoadingFn } from '@helpers';

import {
  AuthNamespace,
  AutocompleteNamespace,
  AutocompleteActionTypes,
  EnumsNamespace,
  AppLocationsNamespace,
} from '@store/types';

import { RulesApiService } from '../../_services/rules.api.service';

import { namespace as RulesNamespace, ActionTypes as RulesActionTypes } from '../../store.types';

import DsbRuleProfitSync from '../../_components/DsbRuleProfitSync.vue';

const dataLoadingOptions = { getterName: RulesActionTypes.GET_RULES, contentName: 'rules' };
const paginationOptions = { contentName: 'rules' };
const searchOptions = {
  initialSearchDataFields: [
    'id', 'status', 'tags', 'productType', 'substrate', 'template',
  ],
  customTypes: {
    tags: 'array',
  },
};

export default {
  name: 'DsbRules',

  components: {
    DsbRuleProfitSync,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      tags: [],

      ruleId: '',

      proFITSyncDialog: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
    ...mapState(AutocompleteNamespace, [
      'productAutocomplete',
      'substrateAutocomplete',
      'pressSheetsTemplatesAutocomplete',
    ]),
    ...mapState(RulesNamespace, {
      rulesTotalCount: ({ rulesTotalCount }) => rulesTotalCount,
      // @TODO remove mapper after backend added state field
      rules: ({ rules }) => rules.map((item) => ({
        ...item,
        // remap rule state from status: Disabled, hack for backend response
        _ruleState: !(item.status || []).includes('Disabled'),
      })),
    }),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),
    ...mapGetters(EnumsNamespace, ['ruleStatuses']),

    searchFieldsConfig() {
      return [
        {
          value: 'tags',
          label: 'Tags',
          items: this.tags,
          type: 'multiselect',
          flex: 'sm4',
        },
        {
          value: 'id',
          label: 'Rule ID',
          type: 'text',
          flex: 'sm4',
        },
        {
          value: 'status',
          label: 'Status',
          items: this.ruleStatuses,
          type: 'select',
          flex: 'sm4',
        },
        {
          value: 'productType',
          label: 'Product Type',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.productAutocomplete,
          callback: this.getProductAutocomplete,
          flex: 'sm4',
        },
        {
          value: 'substrate',
          label: 'Substrate',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.substrateAutocomplete,
          callback: this.getSubstrateAutocomplete,
          flex: 'sm4',
        },
        {
          value: 'template',
          label: 'Template',
          type: 'autocomplete',
          placeholder: 'Start typing...',
          items: this.pressSheetsTemplatesAutocomplete,
          callback: this.getPressSheetsTemplatesAutocomplete,
          flex: 'sm4',
        },
      ];
    },

    headers() {
      return [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status', width: '180px' },
        { text: 'Product Type', value: 'productType' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Press Sheet Templates', value: 'pressSheetTemplates' },
        { text: 'Substrate', value: 'substrate' },
        { text: 'UV Orientation / Lam / Variable UV / Cover / Foil / RC', value: 'coverLamUVFoil' },
        { text: 'Hours', value: 'hoursBeforeTruck' },
        { text: '', value: 'actions', width: this.hadAdminRole ? '140px' : '50px' },
      ];
    },
  },

  watch: {
    suffix: {
      handler(suffix) {
        if (suffix) {
          this.getTags(suffix);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(RulesNamespace, [RulesActionTypes.GET_RULES]),

    ...mapActions(AutocompleteNamespace, {
      getProductAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_PRODUCT_AUTOCOMPLETE,
        { query },
      ),
      getSubstrateAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_SUBSTRATE_AUTOCOMPLETE,
        { query },
      ),
      getPressSheetsTemplatesAutocomplete: (dispatch, query) => dispatch(
        AutocompleteActionTypes.GET_PRESS_SHEETS_TEMPLATES_AUTOCOMPLETE,
        { query },
      ),
    }),

    removeRule(id) {
      this.$VBlackerTheme.alert.warning({
        text: `Remove rule "${id}"`,
      }, () => this.wrapToLoadingFn({
        req: RulesApiService.removeRule.bind({}, { suffix: this.suffix, id }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$_fetchData();
          this.$VBlackerTheme.notification
            .success(`Rule "${id}" successfully removed`);
        },
      }));
    },

    getTags(suffix) {
      this.wrapToLoadingFn({
        req: RulesApiService.getTags.bind({}, { suffix }),
        onSuccess: (r) => {
          this.tags = r;
        },
        onReject: () => {},
      });
    },

    showProFITSyncModal(id) {
      this.ruleId = id;
      this.proFITSyncDialog = true;
    },

    formatUVOrientationStrings(items) {
      return items.map((str) => str
        .replace('And', '+')
        .split('')
        .filter((s) => s === s.toUpperCase())
        .join(''));
    },

    genGUID,
    wrapToLoadingFn,
  },
};
